import React, {useEffect, useRef, useState} from 'react'
import {Link, navigate} from "gatsby";
import api from "../../api/credentials";
import {Snackbar} from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from "react-accessible-accordion";
import {format} from "date-fns";
import {es} from "date-fns/locale";
const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

const OrderDetailsContent = ({order}) => {
    let selectHourText = "Seleccione una opción";
    const textInput = useRef(null);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [creating, setCreating] = useState(false);
    const [creatingBooking, setCreatingBooking] = useState(false);

    const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
    });
    const [newBooking, setNewBooking] = useState({
        facility_id: '',
        order_id: order.id,
        serviceDate: '',
        serviceHour: "",
        options: [],
    });

    const [showForm, setShowForm] = useState(false);
    const [success, setSuccess] = useState(null);
    const refundOrder = (order) => {
        if (window.confirm("¿Seguro que quieres tramitar la devolución?")) {
            api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                api.post(`${process.env.API_URL}/order/refund?order=${order}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((data) => {
                        navigate("/orders");
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired
                                localStorage.removeItem("user");
                                window.dispatchEvent(new Event("removeUser"));
                                navigate("/signin");
                                break;

                            default:
                                setError(error.response.data.error);
                        }
                    });
            });
        }
    };

    const handleClose = () => {
        setError(null);
    };
    const handleChange = (event) => {
        setError(null);

        const newObject = Object.assign({}, newBooking);
        switch (event.target.name) {

            case "serviceDate":
                Object.assign(newObject, {serviceHour: "", options: []});
                break;
            case "serviceHour":
                break;

            case 'facility_id':
                Object.assign(newObject, {serviceHour: "", serviceDate: '', options: []});
                break;
        }


        Object.assign(newObject, {[event.target.name]: event.target.value});


        setNewBooking(newObject);
    };
    const getStatus = (status) => {
        console.log(status);
        switch (status) {
            case "completed":
                return (
                    <>
                        {/* <button onClick={() => refundOrder(order.id)} className='btn-refund-order w-100'>
                            Devolver
                        </button> */}
                        <p className="badge rounded-pill bg-success">Completada</p>
                    </>
                );
            case "refunded":
                return <p className="badge rounded-pill bg-warning text-dark">Devuelto</p>;
            case "failed":
                return <p className="badge rounded-pill bg-danger">Compra fallida</p>;
            case "pending":
                return <p className="badge rounded-pill bg-secondary">Pendiente</p>;
            case "cancelled":
                return <p className="badge rounded-pill bg-danger">Cancelado</p>;
            default:
                return <p className="badge rounded-pill bg-dark">Estado desconocido</p>;
        }
    };
    const showImg = (img) => {
        if (!show) {
            var div = document.createElement("div");
            div.id = "darkbox";
            div.innerHTML = '<img class="darkboximg" src="' + img + '" />';
            document.body.appendChild(div);
            let box = document.getElementById("darkbox");

            box.addEventListener("click", (event) => {
                let element = document.getElementById("darkbox");
                element.parentNode.removeChild(element);

                setShow(false);
            });

            setShow(true);
        } else {
            // Remove it
            let element = document.getElementById("darkbox");
            element.parentNode.removeChild(element);

            setShow(false);
        }
    };
    const openTabSection = (evt, tabName, order) => {
        console.log(tabName);
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        tabcontent = Array.from(tabcontent).filter((element) => element.id.includes(order));
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("btn-link");
        tablinks = Array.from(tablinks).filter((element) => element.id.includes(order));
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" current", "");
        }

        document.getElementById(tabName).style.display = "block";
        document.getElementById(tabName).className += " fadeInUp animated";
        if (evt !== null) {

            evt.currentTarget.className += " current";
        } else {
            document.getElementById('btn-' + tabName).className += " current";
        }
    }
    const showBookingDate = (item) => {
        const pb = item.service.facility.permanent_bookings.find((element) => parseInt(element.id) === parseInt(item.permanent_booking_id));
        if (pb) {
            return format(new Date(pb.day), "dd/MM/yyyy", {locale: es}) + ` (${pb.start.split(":").slice(0, 2).join(":")}-${pb.end.split(":").slice(0, 2).join(":")})`;
        } else {
            return "No disponible";
        }
    };
    const showSeat = (meta) => {
        const seat = meta.seat;
        if (seat) {
            return `Fila: ${seat.row} - Butaca: ${seat.position}`;
        } else {
            return "No disponible";
        }
    };
    const cancel = () => {
        setCreating(false);
        const newObject = Object.assign({}, newBooking);
        Object.assign(newObject, {facility_id: "", serviceDate: "", serviceHour: "", options: []});
        setNewBooking(newObject);
    }
    const createBooking = () => {
        setError(null);
        setCreatingBooking(true);
        // if (service.expedientable && (newBooking.expedient === "" || newBooking.expedient === null)) {
        //     setError('Debe rellenar el Nº de expediente')
        //     setCreating(false)
        //     return false;

        // }


        // if (service.with_multiple_booking && (newBooking.facility_id === "" || newBooking.facility_id === null)) {
        //     setError("Debe seleccionar una instalación");
        //     setCreatingBooking(false);

        //     return false;
        // }
        // if (service.with_booking && (newBooking.serviceDate === "" || newBooking.serviceDate === null)) {
        //     setError("Debe seleccionar fecha para la reserva");
        //     setCreatingBooking(false);

        //     return false;
        // }
        // if (service.with_booking && service.with_time && ((!service.with_multiple_booking && service.facility.slot) || (service.with_multiple_booking)) && (newBooking.serviceHour === "" || newBooking.serviceHour === null)) {
        //     setError("Debe seleccionar hora para la reserva");
        //     setCreatingBooking(false);

        //     return false;
        // }

        // api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
        //     api.post(`${process.env.API_URL}/booking`, {booking: newBooking, allcart: allcart})
        //         .then((response) => {
        //             if (localStorage.getItem("cart") !== null) {
        //                 var cart = JSON.parse(localStorage.getItem("cart"));
        //             } else {
        //                 var cart = [];
        //             }
        //             cart.push(newBooking);
        //             localStorage.setItem("cart", JSON.stringify(cart));

        //             setSuccess("Añadido a la cesta correctamente");
        //             window.dispatchEvent(new Event("storage"));
        //             window.location.href = "/cart";
        //         })
        //         .catch((error) => {
        //             setCreatingBooking(false);

        //             switch (error.response.status) {
        //                 case 401: // Not logged in
        //                 case 419: // Session expired
        //                     localStorage.removeItem("user");
        //                     window.dispatchEvent(new Event("removeUser"));
        //                     navigate("/signin");
        //                     break;

        //                 default:
        //                     setError(error.response.data.error);
        //             }
        //         });
        // });
    };
    return (
        <section className="order-area services-details-desc">
            <div className="container">
                <div className="mb-5 row">
                    <div className="col-12">
                        <div className="orders-accordion">
                            <Accordion preExpanded={[order.id]}>
                                {order.items.map((item) => (
                                    <>
                                        <AccordionItem uuid={order.id} key={`accordion` + item.id}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Nº de pedido: {item.locator}
                                                    <span className="float-end">Total: {formatter.format(order.total)}</span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className="p-3">
                                                <div className="row">
                                                    <div className="mt-3 col-md-9 col-12">
                                                        <div className="d-flex">
                                                            <div>
                                                                <Link to={`/service-details?slug=${item.service.slug}`}>{item.service.name}</Link>
                                                                <br />
                                                                <small className="text-muted">
                                                                    {formatter.format(item.price)} x {item.units} {item.units > 1 ? "unidades" : "unidad"}
                                                                </small>
                                                                {item.uses > 0 && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Usos restantes: {item.uses}</small>
                                                                    </>
                                                                )}
                                                                {item.zone_id && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Instalación: {item.service.facility?.name}</small>
                                                                        <br />
                                                                        <small className="text-muted">Zona: {item.service.facility?.zones.find((element) => parseInt(element.id) === parseInt(item.zone_id))?.name}</small>
                                                                    </>
                                                                )}
                                                                {item.permanent_booking_id && (
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted">Fecha: {showBookingDate(item)}</small>
                                                                    </>
                                                                )}
                                                                {item.meta.length > 0 && (
                                                                    <>
                                                                        {item.meta.map((mt) => (
                                                                            <span key={'order-' + item.id + 'meta' + mt.id}>
                                                                                <br />
                                                                                <small className="text-muted">{showSeat(mt)}</small>
                                                                            </span>
                                                                        ))}
                                                                    </>
                                                                )}
                                                                {/* {item.booking && (
                                                                    <>
                                                                        <br />
                                                                        <strong>Datos de la reserva</strong>
                                                                        <br />
                                                                        <span>Inicio: {format(new Date(item.booking.starts_at), "dd/MM/yyyy HH:mm")}</span>
                                                                        <br />
                                                                        <span>Fin: {format(new Date(item.booking.ends_at), "dd/MM/yyyy HH:mm")}</span>
                                                                    </>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3 col-md-3 col-12 qrcode">
                                                        {item.status === "completed" && (
                                                            <>
                                                                <img
                                                                    src={`${process.env.BACKEND_URL}/qrcodes/${item.locator}.png`}
                                                                    alt={item.service.name}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>


                                                <ul className='nav nav-tabs row' id='myTab'>
                                                    <li key={1} className='col-md-6 col-12 m-0' role="button" onClick={(e) => openTabSection(e, item.id + 'tab1', item.id)}>
                                                        <button
                                                            id={`btn-${item.id}tab1`}
                                                            className='btn btn-link current'
                                                        >
                                                            Estados
                                                        </button>
                                                    </li>
                                                    {item.service.facility_id ? (
                                                        <>
                                                            <li key={2} className='col-md-6 col-12 m-0' role="button" onClick={(e) => openTabSection(e, item.id + 'tab2', item.id)}>
                                                                <button
                                                                    id={`btn-${item.id}tab2`}
                                                                    className='btn btn-link'
                                                                >
                                                                    Reservas
                                                                </button>
                                                            </li>
                                                        </>
                                                    ) : null}


                                                </ul>
                                                <div className='tab-content' style={{paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px'}}>
                                                    <div id={`${item.id}tab1`} className='tab-pane tabs_item block'>
                                                        <div className="row">
                                                            <div className="col-12 col-md-12 table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Estado</th>
                                                                            <th className="text-end">Fecha</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.statuses.map((status) => (
                                                                            <tr key={'order-' + item.id + 'status' + status.id}>
                                                                                <td>{getStatus(status.status)}</td>
                                                                                <td className="text-end">{format(new Date(status.created_at), "dd/MM/yyyy HH:mm")}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={`${item.id}tab2`} className='tab-pane tabs_item'>
                                                        <div className="row">
                                                            <div className="col-12 col-md-12 table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Instalación</th>
                                                                            <th>Fecha</th>
                                                                            <th>Inicio</th>
                                                                            <th>Fin</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.bookings.map((booking) => (
                                                                            <tr key={'order-' + item.id + 'booking' + booking.id}>
                                                                                <td>
                                                                                    {booking.resource.name}
                                                                                </td>
                                                                                <td>
                                                                                    {format(new Date(booking.starts_at), "dd/MM/yyyy")}
                                                                                </td>
                                                                                <td>
                                                                                    {format(new Date(booking.starts_at), "HH:mm")}
                                                                                </td>
                                                                                <td>
                                                                                    {format(new Date(booking.ends_at), "HH:mm")}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        {item.bookings.length === 0 && (
                                                                            <tr>
                                                                                <td colSpan="4">No hay registros</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                                {item.uses >= 0 && item.service.with_use_booking && item.status === 'completed' ? (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-12 text-end">
                                                                                <a
                                                                                    className="btn-refund-order w-50"
                                                                                    role="button"
                                                                                    href={`/booking?id=${item.id}`}
                                                                                >

                                                                                    <i className="flaticon-agendas"></i> Reservar<span></span>
                                                                                </a>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : null}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={error !== null}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{width: "100%"}}
                >
                    {error}
                </Alert>
            </Snackbar>
        </section>
    );
};

export default OrderDetailsContent;
